.navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    flex-direction: row;
    background-color: #24292e;
    width: 100%;
    z-index: 999;
}

.navbar a {
    padding: 10px 20px;
    text-decoration: none;
    color: rgb(197, 188, 188);
    border-right: 1px solid rgb(99, 98, 98);
}

.navbar a:hover {
    background-color: rgb(22, 21, 21);
}


.markdown {
    display: flex;
    flex-direction: column;
    user-select: text;

    max-width: 1050px;
    min-width: 0;
}

.markdown h1, h2, h3, p, pre, details {
    padding-left: 30px;
    padding-right: 30px;
}

.markdown a {
    color: grey;
}

.markdown img {
    max-width: 100%;
    width: auto;
    height: auto;
}