html, body {
  max-width: 100%;
  overflow-x: hidden;
}

html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;

  color: whitesmoke;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-repeat: no-repeat, repeat, repeat;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f);

  user-select: none;
}

* {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #00000000;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}